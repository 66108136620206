import {
  alpha,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { Equity, ProductType, Scanner, ScannerColumnKey } from '../../types';
import {
  DataGridPremium,
  GridCallbackDetails,
  gridClasses,
  GridColDef,
  GridColumnGroup,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPinnedColumnFields,
  GridPinnedRowsProp,
  GridRowParams,
  GridSlots,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  MuiEvent,
  useGridApiContext,
} from '@spotgamma/x-data-grid-premium';
import {
  RecoilState,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { isMobileState, watchlistsState } from 'states';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { EmptyGridOverlay } from './EmptyGridOverlay';
import { EmptyResultsOverlay } from './EmptyResultsOverlay';
import useSetSym from 'hooks/hiro/useSetSym';
import { scannerUnmodifiableFields } from 'config';
import { SGTooltip } from 'components/core';
import dayjs from 'dayjs';

interface CustomToolbarProps {
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >;
  activeScanner: Scanner | undefined;
  useDefaultColumns: boolean;
  setUseDefaultColumns: SetterOrUpdater<boolean>;
  activeWatchlistIdsState: RecoilState<number[]>;
  defaultColumnOrder: string[];
  columnOrderState: RecoilState<string[]>;
  customSettings?: ReactNode;
  customScannerSettings?: ReactNode;
}

const CustomToolbar = ({
  setFilterButtonEl,
  activeScanner,
  useDefaultColumns,
  setUseDefaultColumns,
  activeWatchlistIdsState,
  defaultColumnOrder,
  columnOrderState,
  customSettings,
  customScannerSettings,
}: CustomToolbarProps) => {
  const apiRef = useGridApiContext();
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const watchlists = useRecoilValue(watchlistsState);
  const activeWatchlistsIds = useRecoilValue(activeWatchlistIdsState);
  const [columnOrder, setColumnOrder] = useRecoilState(columnOrderState);
  const activeWatchlists =
    watchlists?.filter((w) => activeWatchlistsIds.includes(w.id as number)) ??
    [];

  const sxProps: SxProps<Theme> = {
    textTransform: 'capitalize',
    fontSize: {
      xs: 12,
      md: 14,
    },
  };

  const slotProps = {
    button: {
      sx: sxProps,
    },
  };

  const onResetColumnOrder = () => setColumnOrder(defaultColumnOrder);

  const { getSym } = useSetSym();

  const fileName = `${
    getSym(ProductType.EQUITYHUB) ? `${getSym(ProductType.EQUITYHUB)}_` : ''
  }data-table_${dayjs().format('YYYY-MM-DD')}`;

  return (
    <GridToolbarContainer
      sx={{
        padding: '5px',
      }}
    >
      {isMobile ? (
        <Stack
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          gap={2}
          width="100%"
        >
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
            <GridToolbarFilterButton
              slotProps={slotProps}
              ref={setFilterButtonEl}
            />
            <GridToolbarColumnsButton slotProps={slotProps} />
            <GridToolbarExport
              slotProps={{
                button: {
                  sx: sxProps,
                },
              }}
              csvOptions={{
                getRowsToExport: () => apiRef.current.getAllRowIds(),
                fileName,
              }}
              excelOptions={{
                fileName,
              }}
              printOptions={{ disableToolbarButton: true }}
            />
            {customScannerSettings}
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            <SGTooltip title="Restore Default Column Order">
              <IconButton
                sx={{
                  color:
                    columnOrder === defaultColumnOrder
                      ? theme.palette.gray
                      : theme.palette.primary.main,
                }}
                onClick={onResetColumnOrder}
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            </SGTooltip>

            {activeScanner && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={useDefaultColumns}
                    onClick={() => setUseDefaultColumns((prev) => !prev)}
                  />
                }
                label={
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: {
                        xs: 12,
                        sm: 13,
                      },
                      color: theme.palette.primary.main,
                    }}
                  >
                    Use Default Columns
                  </Typography>
                }
              />
            )}

            {customSettings}
          </Stack>

          {activeWatchlists.length > 0 && (
            <Typography
              sx={{
                color: theme.palette.text.secondary,
                fontSize: {
                  xs: 11,
                  sm: 13,
                },
              }}
            >
              Limited to watchlists:{' '}
              {activeWatchlists.map((w) => w.name).join(', ')}
            </Typography>
          )}
        </Stack>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
          width="100%"
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <GridToolbarFilterButton
              slotProps={slotProps}
              ref={setFilterButtonEl}
            />
            <GridToolbarColumnsButton slotProps={slotProps} />
            <GridToolbarExport
              slotProps={{
                button: {
                  sx: sxProps,
                },
              }}
              csvOptions={{
                getRowsToExport: () => apiRef.current.getAllRowIds(),
                fileName,
              }}
              excelOptions={{
                fileName,
              }}
              printOptions={{ disableToolbarButton: true }}
            />
            {customScannerSettings}
            <SGTooltip title="Restore Default Column Order">
              <IconButton
                sx={{
                  color:
                    columnOrder === defaultColumnOrder
                      ? theme.palette.gray
                      : theme.palette.primary.main,
                }}
                onClick={onResetColumnOrder}
              >
                <SettingsBackupRestoreIcon />
              </IconButton>
            </SGTooltip>
            {activeScanner && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={useDefaultColumns}
                    onClick={() => setUseDefaultColumns((prev) => !prev)}
                  />
                }
                label={
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      fontSize: {
                        xs: 12,
                        sm: 13,
                      },
                      color: theme.palette.primary.main,
                    }}
                  >
                    Use Default Columns
                  </Typography>
                }
              />
            )}
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            {activeWatchlists.length > 0 && (
              <Typography
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: {
                    xs: 11,
                    sm: 13,
                  },
                }}
              >
                Limited to watchlists:{' '}
                {activeWatchlists.map((w) => w.name).join(', ')}
              </Typography>
            )}
            {customSettings}
          </Stack>
        </Stack>
      )}
    </GridToolbarContainer>
  );
};

interface EquityScannerGridProps {
  columns: GridColDef[];
  columnGroups: GridColumnGroup[];
  data: Equity[];
  activeScanner: Scanner | undefined;
  activeWatchlistIdsState: RecoilState<number[]>;
  useDefaultColumnState: RecoilState<boolean>;
  columnVisibilityModelState: RecoilState<GridColumnVisibilityModel>;
  filterModelState: RecoilState<GridFilterModel>;
  columnOrderState: RecoilState<ScannerColumnKey[]>;
  defaultColumnOrder: string[];
  loading?: boolean;
  onRowClick?: (
    params: GridRowParams,
    event: MuiEvent,
    details: GridCallbackDetails,
  ) => void;
  customSettings?: ReactNode;
  customScannerSettings?: ReactNode;
  scannerPinnedColumnsState: RecoilState<GridPinnedColumnFields>;
  scannerSortModelState: RecoilState<GridSortModel>;
}

export const EquityScannerGrid = ({
  data,
  columns,
  columnGroups,
  activeScanner,
  activeWatchlistIdsState,
  useDefaultColumnState,
  columnVisibilityModelState,
  columnOrderState,
  defaultColumnOrder,
  scannerSortModelState,
  scannerPinnedColumnsState,
  filterModelState,
  customSettings,
  customScannerSettings,
  onRowClick,
  loading,
}: EquityScannerGridProps) => {
  const theme = useTheme();
  const isMobile = useRecoilValue(isMobileState);
  const [useDefaultColumns, setUseDefaultColumns] = useRecoilState(
    useDefaultColumnState,
  );
  const [columnVisibilityModel, setColumnVisibilityModel] = useRecoilState(
    columnVisibilityModelState,
  );
  const [filterModel, setFilterModel] = useRecoilState(filterModelState);
  const [columnOrder, setColumnOrder] = useRecoilState(columnOrderState);
  const [pinnedColumns, setPinnedColumns] = useRecoilState(
    scannerPinnedColumnsState,
  );
  const [sortModel, setSortModel] = useRecoilState(scannerSortModelState);

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null);

  const defaultContentColor = theme.palette.getContrastText(
    theme.palette.background.default,
  );

  const hasFiltersApplied = filterModel?.items && filterModel?.items.length > 0;

  const { getSym } = useSetSym();
  const currentSym = getSym(ProductType.EQUITYHUB);

  const pinnedRow = useMemo(
    () =>
      data.find(
        (eq: Equity) => currentSym && eq.sym === currentSym.toUpperCase(),
      ),
    [data, currentSym],
  );

  const rows = useMemo(() => {
    return hasFiltersApplied
      ? data
      : data.filter((eq: Equity) => eq.sym !== pinnedRow?.sym);
  }, [data, pinnedRow, hasFiltersApplied]);

  const pinnedRowsProp: GridPinnedRowsProp =
    pinnedRow && !hasFiltersApplied ? { top: [pinnedRow] } : {};

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(
        (column) =>
          !scannerUnmodifiableFields.includes(column.field as ScannerColumnKey),
      )
      .map((column) => column.field);
  };

  const orderedColumns = useMemo(() => {
    const orderMap = new Map(columnOrder.map((field, index) => [field, index]));
    const fallback = new Map(
      defaultColumnOrder.map((field, idx) => [field, idx + columns.length]),
    );

    return [...columns].sort(
      (a, b) =>
        (orderMap.get(a.field as ScannerColumnKey) ?? fallback.get(a.field)!) -
        (orderMap.get(b.field as ScannerColumnKey) ?? fallback.get(b.field)!),
    );
  }, [columnOrder, columns, defaultColumnOrder]);

  const handleColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    _event: any,
    _details: any,
  ) => {
    const result = columnOrder.slice(); // Create a copy of the array
    const [removedElement] = result.splice(params.oldIndex, 1); // Remove the element from its original position
    result.splice(params.targetIndex, 0, removedElement); // Insert the element at the new position

    setColumnOrder(result);
  };

  useEffect(() => {
    // initial state on sort model
    if (activeScanner === Scanner.VOL_RISK_PREMIUM && sortModel.length === 0) {
      setSortModel((prev) => [
        ...prev,
        { field: ScannerColumnKey.ivRank, sort: 'desc' },
      ]);
    }
    // should only run once and have empty deps
  }, []);

  return (
    <Box
      sx={{
        marginBottom: 3,
        overflow: 'auto',
        height: '100%',
      }}
    >
      <DataGridPremium
        loading={loading}
        rows={rows}
        pinnedRows={pinnedRowsProp}
        columns={orderedColumns}
        onColumnOrderChange={handleColumnOrderChange}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={(cols) => setPinnedColumns(cols)}
        columnGroupingModel={columnGroups}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          setColumnVisibilityModel(newModel);
          setUseDefaultColumns(false);
        }}
        disableMultipleRowSelection
        disableAggregation
        disableRowGrouping
        pagination={isMobile}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        onRowClick={onRowClick}
        columnHeaderHeight={isMobile ? 70 : 60}
        rowHeight={55}
        density="compact"
        filterModel={filterModel}
        onFilterModelChange={(model: GridFilterModel) => setFilterModel(model)}
        slots={{
          toolbar: CustomToolbar as GridSlots['toolbar'],
          noRowsOverlay: EmptyGridOverlay,
          noResultsOverlay: EmptyResultsOverlay,
        }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns,
          },
          basePopper: {
            sx: {
              '& .MuiSvgIcon-root': {
                color: alpha(defaultContentColor, 0.75),
              },
            },
          },
          panel: {
            anchorEl: filterButtonEl,
            sx: {
              width: '300px',
              transform: '0px',
            },
          },
          toolbar: {
            setFilterButtonEl,
            activeScanner,
            useDefaultColumns,
            setUseDefaultColumns,
            activeWatchlistIdsState,
            columnOrderState,
            defaultColumnOrder,
            customSettings,
            customScannerSettings,
          },
          filterPanel: {
            filterFormProps: {
              // Customize inputs by passing props
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small',
                },
              },
            },
            sx: {
              gap: 2,

              '& .MuiButtonBase-root': {
                textTransform: 'capitalize',
              },
              '& .MuiDataGrid-filterForm': { p: 3, mt: 6 },
              '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
              '& .MuiDataGrid-filterFormColumnInput': { mr: 2 },
              '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: alpha(theme.palette.primary.main, 0.5),
              },
            },
          },
        }}
        sx={{
          borderRadius: 2,
          outline: 'none',
          backgroundColor: theme.palette.background.default,
          border: 'none',
          '& .hideRightSeparator > .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-filler': {
            display: 'none',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: theme.palette.scannerGrid.pinnedRow,
          },
          '& .MuiDataGrid-pinnedRows': {
            '& .MuiDataGrid-row': {
              '& .MuiDataGrid-cell': {
                backgroundColor: theme.palette.scannerGrid.pinnedRow,
              },
            },
          },
          '& .MuiDataGrid-pinnedColumns': {
            backgroundColor: theme.palette.scannerGrid.pinnedColumn,
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer', // Makes the cursor a hand sign when hovering over rows
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: alpha(theme.palette.text.primary, 0.1),
          },
          '& .custom-group-header': {
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              justifyContent: 'center',
              height: '35px',
            },
          },
          '& .grid-header-cell': {
            backgroundColor: theme.palette.scannerGrid.mainHeader,
            ':hover': {
              backgroundColor: alpha(theme.palette.text.primary, 0.1),
            },
            border: 'none',
          },
          '& .MuiDataGrid-topContainer': {
            border: 'none',
          },
          '& .MuiDataGrid-columnHeader': {
            border: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            border: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            border: 'none',
          },
          '& .MuiDataGrid-scrollbar': {
            background: theme.palette.background.default,
          },
          '& .MuiDataGrid-sortIcon': {
            color: defaultContentColor,
          },
          '& .MuiDataGrid-menuIconButton': {
            color: defaultContentColor,
          },
          '& .MuiDataGrid-filterIcon': {
            color: defaultContentColor,
          },
          '& .MuiDataGrid-menuIconButton:hover': {
            backgroundColor: theme.palette.background.paper,
            color: alpha(defaultContentColor, 0.5),
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'flex-start',
          },
          '& .MuiDataGrid-cell': {
            color: defaultContentColor,
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiDataGrid-row .MuiDataGrid-cell': {
            border: 'none',
          },
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: 'none',
            },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            },
        }}
      />
    </Box>
  );
};
