import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Brush,
} from 'recharts';
import {
  volInitialDataState,
  volZoomConfigState,
} from '../../../states/indices';
import {
  formatAsCompactNumberCallback,
  getUtcYMD,
  getZoomConfigRefArea,
} from '../../../util';
import dayjs from 'dayjs';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import useBrushZoom from '../../../hooks/useBrushZoom';
import {
  VolDuration,
  VOL_DURATION_RADIO_OPTIONS,
  SymSelectorSettings,
  Vol,
  IndicesContentType,
  Candle,
} from '../../../types';
import { logReturns, computeVolatility } from '../../../util/shared/volatility';
import { Loader, ZoomOutButton } from '../../shared';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';
import SettingsPopout from '../../shared/SettingsPopout';

interface VolatilityChartControlsProps {
  volDurations: Set<VolDuration>;
  setVolDurations: Dispatch<SetStateAction<Set<VolDuration>>>;
  volDurationsStyleMapping: Record<
    VolDuration,
    { color: string; label: string }
  >;
}

export const VolatilityChartControls = ({
  volDurations,
  setVolDurations,
  volDurationsStyleMapping,
}: VolatilityChartControlsProps) => {
  return (
    <SettingsPopout
      title="Realized Volatility Settings"
      popperID="real-vol-chart-controls"
      placement="bottom-end"
    >
      <Typography
        sx={{
          fontSize: '14px',
        }}
      >
        Durations
      </Typography>
      <Grid
        container
        direction="column"
        justifyContent="space-around"
        alignItems="flex-start"
      >
        <Grid item>
          {VOL_DURATION_RADIO_OPTIONS.map((volDuration) => {
            return (
              <FormControlLabel
                key={volDuration}
                value="bottom"
                control={
                  <Switch
                    size="small"
                    color="primary"
                    checked={volDurations.has(volDuration)}
                    onChange={(e) => {
                      const updatedDurations = volDurations;
                      if (e.target.checked) {
                        updatedDurations.add(volDuration);
                      } else {
                        updatedDurations.delete(volDuration);
                      }
                      setVolDurations(new Set(updatedDurations));
                    }}
                    sx={{
                      fontSize: '14px',
                      '&.MuiSwitch-root .MuiSwitch-switchBase': {
                        color: volDurationsStyleMapping[volDuration],
                      },
                      '&.MuiSwitch-root .Mui-checked': {
                        color: volDurationsStyleMapping[volDuration],
                      },
                      '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: `${volDurationsStyleMapping[volDuration].color} !important`,
                        },
                    }}
                  />
                }
                label={volDurationsStyleMapping[volDuration].label}
                labelPlacement="end"
                sx={{ marginBottom: '12px' }}
              />
            );
          })}
        </Grid>
      </Grid>
    </SettingsPopout>
  );
};

interface VolatilityChartProps {
  selectedSym: string;
  symSelectorSettings?: SymSelectorSettings;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  data: Candle[];
  isLoading?: boolean;
}

export const VolatilityChart = ({
  chartStyleOverrides,
  selectedSym,
  containerStyleOverrides,
  symSelectorSettings,
  data,
  isLoading,
}: VolatilityChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [initialData, setInitialData] = useRecoilState(volInitialDataState);
  const [zoomConfig, setZoomConfig] = useRecoilState(volZoomConfigState);
  const [toggledDurations, setToggledDurations] = useState<Set<VolDuration>>(
    new Set(VOL_DURATION_RADIO_OPTIONS),
  );

  const { zoomChartConfig } = useBrushZoom<Vol>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    function generateChartData() {
      if (data) {
        const epochMillis: number[] = data.map((d: any) =>
          dayjs(d.datetime).valueOf(),
        );
        const prices: number[] = data.map((d: any) => parseInt(d.close));
        const logRet = logReturns(prices);
        const volatility_5 = computeVolatility(logRet, 5);
        const volatility_20 = computeVolatility(logRet, 20); // 1-month
        const volatility_40 = computeVolatility(logRet, 40); // 2-month
        const volatility_60 = computeVolatility(logRet, 60); // 3-month
        const volatility_120 = computeVolatility(logRet, 120); // 6-month

        const volData = epochMillis.map((epoch_millis, idx) => ({
          epoch_millis,
          five_day_vol: volatility_5[idx - 5 + 1],
          one_month_vol: volatility_20[idx - 20 + 1],
          two_month_vol: volatility_40[idx - 40 + 1],
          three_month_vol: volatility_60[idx - 60 + 1],
          six_month_vol: volatility_120[idx - 120 + 1],
        }));

        setZoomConfig((prev) => ({ ...prev, data: volData }));
        setInitialData(volData);
      }
    }
    generateChartData();
  }, [isLoading, data]);

  const VOL_DURATION_STYLE_MAPPING = {
    [VolDuration.FIVE_DAY_VOL]: {
      label: '5 Day',
      color: theme.palette.indices.vol[VolDuration.FIVE_DAY_VOL],
    },
    [VolDuration.ONE_MONTH_VOL]: {
      label: '1 Month',
      color: theme.palette.indices.vol[VolDuration.ONE_MONTH_VOL],
    },
    [VolDuration.TWO_MONTH_VOL]: {
      label: '2 Month',
      color: theme.palette.indices.vol[VolDuration.TWO_MONTH_VOL],
    },
    [VolDuration.THREE_MONTH_VOL]: {
      label: '3 Month',
      color: theme.palette.indices.vol[VolDuration.THREE_MONTH_VOL],
    },
    [VolDuration.SIX_MONTH_VOL]: {
      label: '6 Month',
      color: theme.palette.indices.vol[VolDuration.SIX_MONTH_VOL],
    },
  };

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        symbol={selectedSym}
        symSelectorSettings={symSelectorSettings}
        type={IndicesContentType.VOLATILITY}
        title="Realized Volatility"
        expandable
        customController={
          <>
            <VolatilityChartControls
              volDurations={toggledDurations}
              setVolDurations={setToggledDurations}
              volDurationsStyleMapping={VOL_DURATION_STYLE_MAPPING}
            />
            <ZoomOutButton
              zoomConfig={zoomConfig}
              setZoomConfig={setZoomConfig}
              initialData={initialData}
              overrideDefault={{
                leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
                rightIdx: initialData.length - 1,
              }}
            />
          </>
        }
      />
      {zoomConfig.data && (
        <ChartWatermarkContainer
          ref={ref}
          style={{ ...chartStyleOverrides }}
          size={25}
          offsetX={55}
          offsetY={40}
        >
          <ResponsiveContainer>
            <LineChart
              margin={{ ...DEFAULT_CHART_MARGINS, right: 65 }}
              {...zoomChartConfig}
            >
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                allowDataOverflow
                dataKey="epoch_millis"
                tick={{ fontSize: 11 }}
                label={{
                  ...DEFAULT_X_AXIS_STYLES,
                  value: 'Trade Date',
                  offset: 3,
                }}
                tickFormatter={getUtcYMD}
                domain={['dataMin', 'dataMax']}
                type="number"
              />
              <Brush
                dataKey="epoch_millis"
                tickFormatter={getUtcYMD}
                startIndex={zoomConfig.leftIdx}
                endIndex={zoomConfig.rightIdx}
                onChange={(brushIndices: any) =>
                  setZoomConfig((prev) => ({
                    ...prev,
                    leftIdx: brushIndices.startIndex,
                    rightIdx: brushIndices.endIndex,
                  }))
                }
                height={20}
                travellerWidth={15}
                stroke={theme.palette.gray}
                fill={theme.palette.background.paper}
                alwaysShowText
              />
              <YAxis
                allowDataOverflow
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 11 }}
                tickFormatter={formatAsCompactNumberCallback}
                label={{
                  value: 'Realized Volatility',
                  ...DEFAULT_Y_AXIS_STYLES,
                }}
              />
              <Tooltip
                formatter={(v: string) => v.toLocaleString()}
                itemStyle={{ fontSize: '11px' }}
                labelFormatter={(value) =>
                  `${(value && dayjs(value).format('L')) || ''}`
                }
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              {Array.from(toggledDurations).map((volDuration) => (
                <Line
                  key={VOL_DURATION_STYLE_MAPPING[volDuration].label}
                  type="monotone"
                  dataKey={volDuration}
                  name={VOL_DURATION_STYLE_MAPPING[volDuration].label}
                  stroke={VOL_DURATION_STYLE_MAPPING[volDuration].color}
                  dot={false}
                />
              ))}
              {getZoomConfigRefArea(zoomConfig)}
            </LineChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      )}
    </Box>
  );
};
