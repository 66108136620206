import { Typography } from '@mui/material';
import {
  CompassChartData,
  CompassDesc,
  CompassSeverity,
  CompassSymbolData,
  StrategyCompassMode,
  StrategyCompassXYZAxis,
  StrategyCompassXYZAxisNotPercents,
  StrategyCompassXYZAxisReadableMap,
} from '../types/compass';
import { COMBINED_SIGNALS } from '../config';
import { CompassParams } from '../hooks/scanners/useCompassParams';

const VOWELS = new Set(['a', 'e', 'i', 'o', 'u']);
export const aOrAn = (word: string) => {
  if (word.length === 0) {
    return word;
  }
  return `${VOWELS.has(word[0]) ? 'an' : 'a'} ${word}`;
};

export const toPercentString = (num: number) => (num * 100.0).toFixed(2);

export const getTradeInfo = (
  data: CompassChartData,
  xAxis: StrategyCompassXYZAxis,
  yAxis: StrategyCompassXYZAxis,
  zAxis?: StrategyCompassXYZAxis,
) => {
  const xSev = calculateSeverity(data.x, xAxis);
  const ySev = calculateSeverity(data.y, yAxis);
  const zSev = zAxis && data.z ? calculateSeverity(data.z!, yAxis) : undefined;

  return {
    tooltipXDesc: (COMPASS_DESCRIPTIONS[xSev] as CompassDesc)[xAxis],
    tooltipYDesc: (COMPASS_DESCRIPTIONS[ySev] as CompassDesc)[yAxis],
    tooltipZDesc: zSev
      ? (COMPASS_DESCRIPTIONS[zSev] as CompassDesc)[zAxis!]
      : undefined,
    trade: getTrade(xSev, ySev),
  };
};

export const COMPASS_DESCRIPTIONS = {
  [CompassSeverity.VERY_SLIGHT]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'very high chance of a bullish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'extremely cheap',
    [StrategyCompassXYZAxis.IvPct]: 'extremely cheap',
    [StrategyCompassXYZAxis.SkewRank]: 'extremely cheap',
    [StrategyCompassXYZAxis.Rsi]: 'extremely oversold',
  },
  [CompassSeverity.SLIGHT]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'high chance of a bullish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'very cheap',
    [StrategyCompassXYZAxis.IvPct]: 'very cheap',
    [StrategyCompassXYZAxis.SkewRank]: 'very cheap',
    [StrategyCompassXYZAxis.Rsi]: 'very oversold',
  },
  [CompassSeverity.MILD]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'above average chance of a bullish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'cheap',
    [StrategyCompassXYZAxis.IvPct]: 'cheap',
    [StrategyCompassXYZAxis.SkewRank]: 'cheap',
    [StrategyCompassXYZAxis.Rsi]: 'oversold',
  },
  [CompassSeverity.NEUTRAL_LOW]: {
    [StrategyCompassXYZAxis.Bollinger]: 'average bullish price reversal risk',
    [StrategyCompassXYZAxis.IvRank]: 'slightly cheap',
    [StrategyCompassXYZAxis.IvPct]: 'slightly cheap',
    [StrategyCompassXYZAxis.SkewRank]: 'slightly cheap',
    [StrategyCompassXYZAxis.Rsi]: 'neither oversold or overbought',
  },
  [CompassSeverity.NEUTRAL_HIGH]: {
    [StrategyCompassXYZAxis.Bollinger]: 'average bearish price reversal risk',
    [StrategyCompassXYZAxis.IvRank]: 'slightly expensive',
    [StrategyCompassXYZAxis.IvPct]: 'slightly expensive',
    [StrategyCompassXYZAxis.SkewRank]: 'slightly expensive',
    [StrategyCompassXYZAxis.Rsi]: 'neither oversold or overbought',
  },
  [CompassSeverity.MODERATE]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'above average chance of a bearish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'expensive',
    [StrategyCompassXYZAxis.IvPct]: 'expensive',
    [StrategyCompassXYZAxis.SkewRank]: 'expensive',
    [StrategyCompassXYZAxis.Rsi]: 'overbought',
  },
  [CompassSeverity.HIGH]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'high chance of a bearish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'very expensive',
    [StrategyCompassXYZAxis.IvPct]: 'very expensive',
    [StrategyCompassXYZAxis.SkewRank]: 'very expensive',
    [StrategyCompassXYZAxis.Rsi]: 'very overbought',
  },
  [CompassSeverity.VERY_HIGH]: {
    [StrategyCompassXYZAxis.Bollinger]:
      'very high chance of a bearish price reversal',
    [StrategyCompassXYZAxis.IvRank]: 'extremely expensive',
    [StrategyCompassXYZAxis.IvPct]: 'extremely expensive',
    [StrategyCompassXYZAxis.SkewRank]: 'extremely expensive',
    [StrategyCompassXYZAxis.Rsi]: 'extremely overbought',
  },
};

const getTrade = (xSev: CompassSeverity, ySev: CompassSeverity) => {
  if (ySev === CompassSeverity.NEUTRAL_LOW) {
    if (xSev === CompassSeverity.NEUTRAL_LOW) {
      return 'buying call spreads';
    } else if (xSev === CompassSeverity.NEUTRAL_HIGH) {
      return 'buying put spreads';
    } else if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'buying calls';
    } else {
      return 'buying puts';
    }
  } else if (ySev === CompassSeverity.NEUTRAL_HIGH) {
    if (xSev === CompassSeverity.NEUTRAL_LOW) {
      return 'selling put spreads';
    } else if (xSev === CompassSeverity.NEUTRAL_HIGH) {
      return 'selling call spreads';
    } else if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'selling puts';
    } else {
      return 'selling calls';
    }
  } else if (ySev < CompassSeverity.NEUTRAL_LOW) {
    if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'buying calls';
    } else if (xSev > CompassSeverity.NEUTRAL_HIGH) {
      return 'buying puts';
    } else if (
      xSev === CompassSeverity.NEUTRAL_LOW ||
      xSev === CompassSeverity.NEUTRAL_HIGH
    ) {
      return 'buying a straddle or strangle';
    }
  } else if (ySev > CompassSeverity.NEUTRAL_HIGH) {
    if (xSev < CompassSeverity.NEUTRAL_LOW) {
      return 'selling puts';
    } else if (xSev > CompassSeverity.NEUTRAL_HIGH) {
      return 'selling calls';
    } else if (
      xSev === CompassSeverity.NEUTRAL_LOW ||
      xSev === CompassSeverity.NEUTRAL_HIGH
    ) {
      return 'selling a straddle or strangle';
    }
  }

  return '';
};

export const generateTooltipTextComponent = (
  value: number,
  axis: StrategyCompassXYZAxis | undefined,
  severityDescription: string,
  data: CompassSymbolData,
) => {
  if (axis == null) {
    return null;
  }

  const displayVal = displayValue(value, axis);

  switch (axis) {
    case StrategyCompassXYZAxis.IvRank:
      return (
        <Typography>
          {data.sym} has a 1 month IV in the <b>{displayVal}</b> of its 52-wk
          range, indicating {severityDescription} IV relative to its history.
        </Typography>
      );
    case StrategyCompassXYZAxis.IvPct:
      return (
        <Typography>
          {data.sym} has a 1 month IV which is higher than it has been for{' '}
          <b>{displayVal}</b> of the past 52 weeks, indicating{' '}
          {severityDescription} IV relative to its history.
        </Typography>
      );
    case StrategyCompassXYZAxis.Bollinger:
      return (
        <Typography>
          {data.sym}'s current price is in the <b>{displayVal}</b> of it's
          Bollinger Band range, indicating {aOrAn(severityDescription)}.
        </Typography>
      );
    case StrategyCompassXYZAxis.ProximityToCallWall:
      return (
        <Typography>
          {data.sym}, at its current price of {data.price.toFixed(2)}, is{' '}
          <b>{displayVal}</b> away from its Call Wall.
        </Typography>
      );
    case StrategyCompassXYZAxis.ProximityToPutWall:
      return (
        <Typography>
          {data.sym}, at its current price of {data.price.toFixed(2)}, is{' '}
          <b>{displayVal}</b> away from its Put Wall.
        </Typography>
      );
    case StrategyCompassXYZAxis.NextExpiryCallVolumePercent:
      return (
        <Typography>
          {data.sym} has <b>{displayVal}</b> of its total call volume expiring
          after the next expiration.
        </Typography>
      );
    case StrategyCompassXYZAxis.NextExpiryDeltaPercent:
      return (
        <Typography>
          {data.sym} has <b>{displayVal}</b> of its total delta expiring after
          the next expiration.
        </Typography>
      );
    case StrategyCompassXYZAxis.NextExpiryGammaPercent:
      return (
        <Typography>
          {data.sym} has <b>{displayVal}</b> of its total gamma expiring after
          the next expiration.
        </Typography>
      );
    case StrategyCompassXYZAxis.Rsi:
      return (
        <Typography>
          {data.sym} has an RSI of <b>{displayVal}</b>, indicating that it is{' '}
          {severityDescription}.
        </Typography>
      );
    case StrategyCompassXYZAxis.SkewRank:
      return (
        <Typography>
          {data.sym} has a current skew in the <b>{displayVal}</b> of its 52-wk
          range, indicating {severityDescription} skew relative to its history.
        </Typography>
      );
    default:
      return (
        <Typography>
          {data.sym} has a {StrategyCompassXYZAxisReadableMap.get(axis)!} of{' '}
          <b>{displayVal}</b>;
        </Typography>
      );
  }
};

export const generateTooltipText = (
  data: CompassChartData,
  symData: CompassSymbolData,
  compassParams: CompassParams,
) => {
  const { xAxis, yAxis, zAxis, mode } = compassParams;
  const { tooltipXDesc, tooltipYDesc, tooltipZDesc, trade } = getTradeInfo(
    data,
    xAxis,
    yAxis,
    zAxis,
  );
  return (
    <>
      {generateTooltipTextComponent(
        data.unroundedX,
        xAxis,
        tooltipXDesc,
        symData,
      )}
      {generateTooltipTextComponent(
        data.unroundedY,
        yAxis,
        tooltipYDesc,
        symData,
      )}
      {data.unroundedZ != null &&
        generateTooltipTextComponent(
          data.unroundedZ,
          zAxis,
          tooltipZDesc!,
          symData,
        )}
      {mode === StrategyCompassMode.Compass && (
        <>
          <Typography>
            Based on the information above, one might consider{' '}
            <Typography fontWeight="bold" sx={{ textDecoration: 'underline' }}>
              {trade}
            </Typography>
            .
          </Typography>
          <Typography fontSize="9px" marginTop="5px">
            All trading involves risk. The information provided here is not
            investment advice. Your trades are solely the result of your own
            actions, and SpotGamma assumes no liability for them.
          </Typography>
        </>
      )}
    </>
  );
};

export const calculateSeverity = (
  val: number,
  axis: StrategyCompassXYZAxis,
) => {
  const thresholds =
    axis === StrategyCompassXYZAxis.Rsi
      ? [0.1, 0.2, 0.31, 0.5, 0.7, 0.8, 0.9]
      : [0.075, 0.175, 0.25, 0.5, 0.75, 0.85, 0.925];
  // values here for NEUTRAL_LOW and NEUTRAL_HIGH should match SPREAD_THRESHOLD in StrategyCompass
  // to ensure that values in the spread boxes print out the correct recommendation
  if (val < thresholds[0]) {
    return CompassSeverity.VERY_SLIGHT;
  } else if (val < thresholds[1]) {
    return CompassSeverity.SLIGHT;
  } else if (val < thresholds[2]) {
    return CompassSeverity.MILD;
  } else if (val >= thresholds[2] && val < thresholds[3]) {
    return CompassSeverity.NEUTRAL_LOW;
  } else if (val >= thresholds[3] && val <= thresholds[4]) {
    return CompassSeverity.NEUTRAL_HIGH;
  } else if (val > thresholds[4] && val <= thresholds[5]) {
    return CompassSeverity.MODERATE;
  } else if (val > thresholds[5] && val < thresholds[6]) {
    return CompassSeverity.HIGH;
  } else {
    // 92.5 - 100
    return CompassSeverity.VERY_HIGH;
  }
};

export const isValidCompassSymbol = (sym: string) => {
  return (sym?.length ?? 0) > 0 && !COMBINED_SIGNALS.has(sym);
};

export const getModeLabel = (mode: StrategyCompassMode) => {
  switch (mode) {
    case StrategyCompassMode.Compass:
      return 'Trade Compass';
    case StrategyCompassMode.Freeform:
      return 'Freeform';
  }
};

export const calculateDistanceToWall = (price: number, ws: number) =>
  Math.abs(ws - price) / price;

export const zeroOneBound = (num: number | undefined | null) => {
  if (num == null) {
    return undefined;
  }
  // these values could technically be negative or >1. for now, simplest thing is to just 0,1 bound them
  return Math.max(Math.min(num, 1), 0);
};

export const displayValue = (val: number, axis: StrategyCompassXYZAxis) =>
  StrategyCompassXYZAxisNotPercents.has(axis)
    ? `${val}`
    : `${toPercentString(val)}%`;
