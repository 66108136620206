export enum OptionLegType {
  SHORT_PUT = 'SHORT_PUT',
  SHORT_CALL = 'SHORT_CALL',
  LONG_PUT = 'LONG_PUT',
  LONG_CALL = 'LONG_CALL',
}

export interface ModelParameters {
  riskFreeRate: number;
  volatility: number | undefined;
  daysToExpiration: number;
}

export interface OptionLeg {
  legType: OptionLegType;
  strikePrice: number;
  premium: number;
  shares: number;
}

export interface VolatilityData {
  tte: number;
  strikePrices: { [strike: number]: number };
}

export interface VolatilityDataRow {
  strike: number;
  volatility: number;
}

export interface ProfitLossData {
  price: number;
  profit: number;
  volatility: number;
  profitBounds: number[];
}

export interface ProfitLossWithDomain {
  profitLossData: ProfitLossData[];
  domain: number[];
}