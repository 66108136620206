import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Brush,
} from 'recharts';
import {
  vDivInitialDataState,
  vDivZoomConfigState,
} from '../../../states/indices';
import {
  formatAsCompactNumber,
  formatAsCompactNumberCallback,
  getUtcYMD,
  getZoomConfigRefArea,
} from '../../../util';
import dayjs from 'dayjs';
import {
  DEFAULT_BRUSH_ZOOM_CONFIG,
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y2_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import useBrushZoom from '../../../hooks/useBrushZoom';
import {
  VDivRatio,
  VDIV_RATIO_OPTIONS,
  SymSelectorSettings,
  MaxRealVol,
  IndicesContentType,
  Candle,
} from '../../../types';
import { logReturns, computeVolatility } from '../../../util/shared/volatility';
import { Loader, ZoomOutButton } from '../../shared';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';
import SettingsPopout from '../../shared/SettingsPopout';

interface MaxRealVolChartControlsProps {
  vDivRatios: Set<VDivRatio>;
  setVDivRatios: Dispatch<SetStateAction<Set<VDivRatio>>>;
  vDivRatioStyleMapping: Record<VDivRatio, { color: string; label: string }>;
}

export const MaxRealVolChartControls = ({
  vDivRatios,
  setVDivRatios,
  vDivRatioStyleMapping,
}: MaxRealVolChartControlsProps) => {
  return (
    <SettingsPopout
      title="Max Real Vol Chart Settings"
      popperID="max-real-vol-chart-controls"
      placement="bottom-end"
    >
      <Stack direction="row" justifyContent="center" spacing={7}>
        <Typography sx={{ fontSize: '14px' }}>Ratios</Typography>
        <Stack>
          {VDIV_RATIO_OPTIONS.map((vDivRatio) => {
            return (
              <FormControlLabel
                key={vDivRatio}
                value="bottom"
                control={
                  <Switch
                    size="small"
                    color="primary"
                    checked={vDivRatios.has(vDivRatio)}
                    onChange={(e) => {
                      const updatedRatios = vDivRatios;
                      if (e.target.checked) {
                        updatedRatios.add(vDivRatio);
                      } else {
                        updatedRatios.delete(vDivRatio);
                      }
                      setVDivRatios(new Set(updatedRatios));
                    }}
                    sx={{
                      fontSize: '14px',
                      '&.MuiSwitch-root .MuiSwitch-switchBase': {
                        color: vDivRatioStyleMapping[vDivRatio],
                      },
                      '&.MuiSwitch-root .Mui-checked': {
                        color: vDivRatioStyleMapping[vDivRatio],
                      },
                      '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: `${vDivRatioStyleMapping[vDivRatio].color} !important`,
                        },
                    }}
                  />
                }
                label={vDivRatioStyleMapping[vDivRatio].label}
                labelPlacement="end"
                sx={{ marginBottom: '8px' }}
              />
            );
          })}
        </Stack>
      </Stack>
    </SettingsPopout>
  );
};

interface MaxRealVolChartProps {
  selectedSym: string;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  symSelectorSettings?: SymSelectorSettings;
  data: Candle[];
  isLoading?: boolean;
}

export const MaxRealVolChart = ({
  selectedSym,
  chartStyleOverrides,
  containerStyleOverrides,
  symSelectorSettings,
  data,
  isLoading,
}: MaxRealVolChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [initialData, setInitialData] = useRecoilState(vDivInitialDataState);
  const [zoomConfig, setZoomConfig] = useRecoilState(vDivZoomConfigState);
  const [toggledRatios, setToggledRatios] = useState<Set<VDivRatio>>(
    new Set([VDivRatio.TWOMONTH_SIXMONTH]),
  );

  const VDIV_RATIO_STYLE_MAPPING = {
    [VDivRatio.ONEMONTH_THREEMONTH]: {
      label: '1M/3M Realized Volatility',
      color: theme.palette.indices.maxVol.one_three_month,
    },
    [VDivRatio.TWOMONTH_SIXMONTH]: {
      label: '2M/6M Realized Volatility',
      color: theme.palette.indices.maxVol.two_six_month,
    },
  };

  const { zoomChartConfig } = useBrushZoom<MaxRealVol>(
    zoomConfig,
    setZoomConfig,
    'epoch_millis',
    initialData,
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    function generateChartData() {
      if (data) {
        const epochMillis: number[] = data.map((d: any) =>
          dayjs(d.datetime).valueOf(),
        );
        const prices: number[] = data.map((d: any) => parseInt(d.close));
        const logRet = logReturns(prices);
        const volatility_20 = computeVolatility(logRet, 20); // 1-month
        const volatility_40 = computeVolatility(logRet, 40); // 2-month
        const volatility_60 = computeVolatility(logRet, 60); // 3-month
        const volatility_120 = computeVolatility(logRet, 120); // 6-month
        const vDiv_2m_6m = volatility_120.map(
          (v, idx) => volatility_40[idx + 80] / v,
        );
        const vDiv_1m_3m = volatility_60.map(
          (v, idx) => volatility_20[idx + 40] / v,
        );

        const volDivs = epochMillis.map((epoch_millis, idx) => ({
          epoch_millis,
          price: prices[idx],
          vDiv_2m_6m: vDiv_2m_6m[idx - 120 + 1],
          vDiv_1m_3m: vDiv_1m_3m[idx - 60 + 1],
        }));

        setZoomConfig((prev) => ({ ...prev, data: volDivs }));
        setInitialData(volDivs);
      }
    }
    generateChartData();
  }, [isLoading, data]);

  const getHeaderTitle = () => {
    const baseTitle = 'Price';
    if (toggledRatios.size === 0) {
      return baseTitle;
    } else if (
      toggledRatios.has(VDivRatio.ONEMONTH_THREEMONTH) &&
      toggledRatios.has(VDivRatio.TWOMONTH_SIXMONTH)
    ) {
      return `${baseTitle} vs ${
        VDIV_RATIO_STYLE_MAPPING[VDivRatio.ONEMONTH_THREEMONTH].label
      } & ${VDIV_RATIO_STYLE_MAPPING[VDivRatio.TWOMONTH_SIXMONTH].label}`;
    } else if (toggledRatios.has(VDivRatio.ONEMONTH_THREEMONTH)) {
      return `${baseTitle} vs ${
        VDIV_RATIO_STYLE_MAPPING[VDivRatio.ONEMONTH_THREEMONTH].label
      }`;
    } else {
      return `${baseTitle} vs ${
        VDIV_RATIO_STYLE_MAPPING[VDivRatio.TWOMONTH_SIXMONTH].label
      }`;
    }
  };

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        symbol={selectedSym}
        symSelectorSettings={symSelectorSettings}
        type={IndicesContentType.MAX_REAL_VOL}
        title={getHeaderTitle()}
        expandable
        customController={
          <>
            <MaxRealVolChartControls
              vDivRatios={toggledRatios}
              setVDivRatios={setToggledRatios}
              vDivRatioStyleMapping={VDIV_RATIO_STYLE_MAPPING}
            />
            <ZoomOutButton
              zoomConfig={zoomConfig}
              setZoomConfig={setZoomConfig}
              initialData={initialData}
              overrideDefault={{
                leftIdx: DEFAULT_BRUSH_ZOOM_CONFIG.leftIdx,
                rightIdx: initialData.length - 1,
              }}
            />
          </>
        }
      />
      {zoomConfig.data && (
        <ChartWatermarkContainer
          ref={ref}
          style={{
            ...chartStyleOverrides,
          }}
          size={25}
          offsetX={50}
          offsetY={40}
        >
          <ResponsiveContainer>
            <LineChart margin={DEFAULT_CHART_MARGINS} {...zoomChartConfig}>
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                allowDataOverflow
                dataKey="epoch_millis"
                tick={{ fontSize: 11 }}
                label={{
                  ...DEFAULT_X_AXIS_STYLES,
                  value: 'Trade Date',
                  offset: 3,
                }}
                tickFormatter={getUtcYMD}
                domain={['dataMin', 'dataMax']}
                type="number"
              />
              <Brush
                dataKey="epoch_millis"
                tickFormatter={getUtcYMD}
                startIndex={zoomConfig.leftIdx}
                endIndex={zoomConfig.rightIdx}
                onChange={(brushIndices: any) =>
                  setZoomConfig((prev) => ({
                    ...prev,
                    leftIdx: brushIndices.startIndex,
                    rightIdx: brushIndices.endIndex,
                  }))
                }
                height={20}
                travellerWidth={15}
                stroke={theme.palette.gray}
                fill={theme.palette.background.paper}
                alwaysShowText
              />
              <YAxis
                allowDataOverflow
                yAxisId="left"
                domain={['dataMin', 'dataMax']}
                tick={{ fontSize: 11 }}
                tickFormatter={(v: number) => `$${formatAsCompactNumber(v)}`}
                label={{
                  value: 'Price',
                  ...DEFAULT_Y_AXIS_STYLES,
                }}
              />
              <YAxis
                allowDataOverflow
                domain={['dataMin', 'dataMax']}
                yAxisId="right"
                tickFormatter={formatAsCompactNumberCallback}
                tick={{ fontSize: 11 }}
                type="number"
                label={{
                  value: '2M/6M Volatility',
                  ...DEFAULT_Y2_AXIS_STYLES,
                }}
                orientation="right"
              />
              <Tooltip
                formatter={(v: string) => `$${v.toLocaleString()}`}
                itemStyle={{ fontSize: '11px' }}
                labelFormatter={(value) =>
                  `${(value && dayjs(value).format('L')) || ''}`
                }
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              <Line
                yAxisId="left"
                type="monotone"
                dataKey="price"
                name="Price"
                stroke={theme.palette.indices.maxVol.price}
                dot={false}
              />
              {Array.from(toggledRatios).map((vDivRatio) => (
                <Line
                  key={vDivRatio}
                  yAxisId="right"
                  type="monotone"
                  dataKey={vDivRatio}
                  name={VDIV_RATIO_STYLE_MAPPING[vDivRatio].label}
                  stroke={VDIV_RATIO_STYLE_MAPPING[vDivRatio].color}
                  dot={false}
                />
              ))}
              {getZoomConfigRefArea(zoomConfig, 'left')}
            </LineChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      )}
    </Box>
  );
};
