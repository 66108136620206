import { CompassParams } from '../../../hooks/scanners/useCompassParams';
import {
  Chip,
  Stack,
  Typography,
  Button,
  SelectChangeEvent,
} from '@mui/material';
import { CompassData } from '../../../hooks/scanners/useCompassData';
import { useTheme } from '@mui/material/styles';
import { displayValue, getEquitiesForScanner, shadeColor } from '../../../util';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  compassHiddenSymbolsState,
  compassHoveredSymbolState,
  compassSelectedScannersState,
  scannerDataState,
} from '../../../states/scanners';
import { isMobileState } from '../../../states';
import OptionsDropdownSelector from 'components/shared/OptionsDropdownSelector';
import { Equity, Scanner } from 'types';
import { SG_SPECIAL_SCANNERS } from 'config';
import { useCallback } from 'react';

type StrategyCompassEditSymbolsProps = {
  compassData: CompassData;
  compassParams: CompassParams;
};

const ALL_SCANNER_OPTIONS = SG_SPECIAL_SCANNERS.map((scObj) => ({
  label: scObj.label.name,
  value: scObj.scanner,
}));

export const StrategyCompassEditSymbols = ({
  compassParams,
  compassData,
}: StrategyCompassEditSymbolsProps) => {
  const { chartData, visibleChartData } = compassData;
  const {
    setSyms,
    syms,
    zAxisDataKey,
    xAxis,
    yAxis,
    zAxis,
    setActiveWatchlistIdsState,
  } = compassParams;

  const [compassSelectedScanners, setCompassSelectedScanners] = useRecoilState(
    compassSelectedScannersState,
  );
  const scannerData = useRecoilValue(scannerDataState);

  const theme = useTheme();

  const [hiddenSymbols, setHiddenSymbols] = useRecoilState(
    compassHiddenSymbolsState,
  );
  const setHoveredSymbol = useSetRecoilState(compassHoveredSymbolState);
  const isMobile = useRecoilValue(isMobileState);

  const toggleHiddenSymbols = (sym: string) => {
    if (hiddenSymbols.has(sym)) {
      showSymbols([sym]);
    } else {
      hideSymbols([sym]);
    }
  };

  const hideSymbols = (syms: string[]) => {
    setHiddenSymbols(new Set([...hiddenSymbols].concat(syms)));
  };

  const showSymbols = (syms: string[]) => {
    const setArr = [...hiddenSymbols].filter((s) => !syms.includes(s));
    setHiddenSymbols(new Set(setArr));
  };

  const deleteSym = (sym: string) => {
    setSyms(syms.filter((s) => s !== sym));
    setHiddenSymbols(
      (hiddenSyms) => new Set([...hiddenSyms].filter((s) => s !== sym)),
    );
  };

  const deleteAllSyms = () => {
    setSyms([]);
    setHiddenSymbols(new Set());
    setActiveWatchlistIdsState([]);
  };

  const onScannerSelectChange = useCallback(
    (event: SelectChangeEvent<string | string[]>) => {
      const selectedScannerIds = event.target.value as Scanner[];
      setCompassSelectedScanners(selectedScannerIds);

      // Get all possible scanner symbols
      const allScannerSymbols = new Set(
        ALL_SCANNER_OPTIONS.flatMap((sc) =>
          getEquitiesForScanner(sc.value, scannerData),
        ).map((eq) => eq.sym),
      );

      // Get symbols from currently selected scanners
      const selectedScannerSymbols = new Set(
        selectedScannerIds
          .flatMap((scannerId) => getEquitiesForScanner(scannerId, scannerData))
          .map((equity: Equity) => equity.sym),
      );

      // Keep symbols that are either:
      // 1. Not from any scanner (preserve non-scanner symbols)
      // 2. From currently selected scanners
      const updatedSymbols = [
        ...syms.filter(
          (sym) =>
            !allScannerSymbols.has(sym) || selectedScannerSymbols.has(sym),
        ),
        ...Array.from(selectedScannerSymbols),
      ];

      setSyms(Array.from(new Set(updatedSymbols)));
    },
    [scannerData, setCompassSelectedScanners, syms],
  );

  const dropDownSelector = (
    <OptionsDropdownSelector
      label="Scanner"
      value={compassSelectedScanners}
      options={ALL_SCANNER_OPTIONS}
      isMultiple
      onChange={onScannerSelectChange}
      sx={{ maxWidth: '250px' }}
    />
  );

  return (
    <Stack
      sx={{
        marginBottom: '20px',
        width: 1,
        overflowY: 'auto',
        maxHeight: isMobile ? '300px' : undefined,
        marginTop: isMobile ? '50px' : undefined,
        gap: '6px',
        paddingTop: '8px',
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 8,
        }}
      >
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={() =>
            visibleChartData.length === 0
              ? showSymbols(syms)
              : hideSymbols(syms)
          }
          disabled={chartData.length === 0}
        >
          {visibleChartData.length === 0 ? 'Show All' : 'Hide All'}
        </Button>
        {chartData.length > 0 && dropDownSelector}
        <Button
          sx={{
            textTransform: 'none',
            fontSize: '12px',
            width: '75px',
          }}
          onClick={deleteAllSyms}
        >
          Clear All
        </Button>
      </Stack>

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '10px',
          paddingX: '12px',
        }}
      >
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
          }}
        >
          Symbol
        </Typography>
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
            paddingLeft: '3px',
          }}
        >
          Price
        </Typography>
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
            paddingLeft: '5px',
          }}
        >
          X
        </Typography>
        <Typography
          sx={{
            width: '50px',
            textDecoration: 'underline',
          }}
        >
          Y
        </Typography>
        {zAxisDataKey != null && (
          <Typography
            sx={{
              width: '50px',
              textDecoration: 'underline',
            }}
          >
            Z
          </Typography>
        )}
      </Stack>

      {chartData.length === 0 ? (
        <Stack
          sx={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Typography>
            Pick from scanners and add all underlying tickers
          </Typography>
          {dropDownSelector}
        </Stack>
      ) : (
        chartData.map((data) => {
          const chipLabel = (
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                width: '100%',
                height: '32px !important',
                paddingTop: '6px',
              }}
            >
              <Typography sx={{ textAlign: 'center', width: '50px' }}>
                {data.sym}
              </Typography>
              <Typography sx={{ textAlign: 'center', width: '50px' }}>
                {data.symData.price.toFixed(2)}
              </Typography>
              <Typography sx={{ textAlign: 'center', width: '50px' }}>
                {displayValue(data.unroundedX, xAxis)}
              </Typography>
              <Typography sx={{ textAlign: 'center', width: '50px' }}>
                {displayValue(data.unroundedY, yAxis)}
              </Typography>
              {data.unroundedZ != null && (
                <Typography sx={{ textAlign: 'center', width: '50px' }}>
                  {displayValue(data.unroundedZ, zAxis!)}
                </Typography>
              )}
            </Stack>
          );

          const backgroundColor = hiddenSymbols.has(data.sym)
            ? theme.palette.compass.disabledChipBgColor
            : theme.palette.compass.editChipBgColor;
          return (
            <Chip
              label={chipLabel}
              onDelete={() => deleteSym(data.sym)}
              onMouseEnter={() => setHoveredSymbol(data.sym)}
              onMouseLeave={() => setHoveredSymbol(undefined)}
              onClick={() => toggleHiddenSymbols(data.sym)}
              sx={{
                backgroundColor,
                height: '32px !important',
                justifyContent: 'space-between !important',
                '.MuiChip-label': {
                  width: 1,
                },
                ':hover': {
                  cursor: 'pointer',
                  backgroundColor: shadeColor(backgroundColor, -20),
                },
              }}
            />
          );
        })
      )}
    </Stack>
  );
};
