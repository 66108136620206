import { ModelParameters, OptionLeg, OptionLegType } from "types/optionsStrat";

export const DEFAULT_OPTION_LEG: OptionLeg = {
  legType: OptionLegType.LONG_CALL,
  strikePrice: 100,
  premium: 5,
  shares: 1,
};

export const DEFAULT_MODEL_PARAMETERS: ModelParameters = {
  riskFreeRate: 0.03,
  volatility: 0.177,
  daysToExpiration: 1
}