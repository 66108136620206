import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { volHistogramInitialDataState } from '../../../states/indices';
import {
  DEFAULT_CHART_MARGINS,
  DEFAULT_X_AXIS_STYLES,
  DEFAULT_Y_AXIS_STYLES,
} from '../../../config';
import { IndicesHeader } from '../shared/IndicesHeader';
import { SymSelectorSettings, IndicesContentType } from '../../../types';
import { getHistogramData } from '../../../util';
import { logReturns } from '../../../util/shared/volatility';
import { Loader } from '../../shared';
import ChartWatermarkContainer from '../../shared/ChartWatermarkContainer';

interface RealVolHistogramChartProps {
  selectedSym: string;
  chartStyleOverrides?: React.CSSProperties;
  containerStyleOverrides?: SxProps<Theme>;
  symSelectorSettings?: SymSelectorSettings;
  data: any;
  isLoading?: boolean;
}

export const RealVolHistogramChart = ({
  selectedSym,
  chartStyleOverrides,
  containerStyleOverrides,
  symSelectorSettings,
  data,
  isLoading,
}: RealVolHistogramChartProps) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const [initialData, setInitialData] = useRecoilState(
    volHistogramInitialDataState,
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }
    function generateChartData() {
      if (data) {
        const prices: number[] = data.map((d: any) => parseInt(d.close));
        const logRet = logReturns(prices);

        const five_days = getHistogramData(logRet.slice(-5)) || [];
        const twenty_days = getHistogramData(logRet.slice(-20)) || [];

        let accumData: Map<
          number,
          { five_day?: number; twenty_day?: number; label: number }
        > = new Map(
          twenty_days.map((d) => [
            d.x0 || 0,
            { twenty_day: d.length, label: d.x0 || 0 },
          ]),
        );
        accumData = five_days.reduce((groups, d) => {
          let group = groups.get(d.x0 || 0) ?? {
            label: d.x0 || 0,
            twenty_day: 0,
          };
          group = {
            ...group,
            five_day: d.length,
          };
          return groups.set(d.x0 || 0, group);
        }, accumData);
        const histData = Array.from(accumData.values());
        setInitialData(histData.sort((a, b) => a.label - b.label));
      }
    }
    generateChartData();
  }, [isLoading, data]);

  if (isLoading) {
    <Loader isLoading={isLoading} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: '8px',
        ...containerStyleOverrides,
      }}
    >
      <IndicesHeader
        type={IndicesContentType.HIST_RETURNS}
        title="5 Day & 1 Month Return Histogram"
        expandable
        symbol={selectedSym}
        symSelectorSettings={symSelectorSettings}
      />
      {initialData && (
        <ChartWatermarkContainer
          ref={ref}
          style={{
            ...chartStyleOverrides,
          }}
          size={25}
          offsetX={55}
          offsetY={40}
        >
          <ResponsiveContainer>
            <BarChart margin={DEFAULT_CHART_MARGINS} data={initialData}>
              <CartesianGrid
                strokeDasharray="1 10"
                stroke={theme.palette.gray}
              />
              <XAxis
                dataKey="label"
                tickFormatter={(v: string) => `${parseFloat(v) * 100}%`}
                tick={{ fontSize: 11 }}
                label={{
                  value: 'Close to Close Return %',
                  ...DEFAULT_X_AXIS_STYLES,
                }}
              />
              <YAxis
                tick={{ fontSize: 11 }}
                label={{
                  value: 'Count',
                  ...DEFAULT_Y_AXIS_STYLES,
                }}
              />
              <Tooltip
                formatter={(v: string) => v.toLocaleString()}
                labelFormatter={(v: string) =>
                  `Close to Close Return ${
                    parseFloat(v) < 0 ? '-' : ''
                  }%${Math.abs(parseFloat(v) * 100)}`
                }
                itemStyle={{ fontSize: '11px' }}
                contentStyle={{
                  color: theme.palette.text.primary,
                  border: 'none',
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.palette.shadows.paperBoxShadow,
                }}
                separator=": "
              />
              <Bar
                dataKey="five_day"
                fill="#d10000"
                name="5 Day"
                stackId="logRet"
              />
              <Bar
                dataKey="twenty_day"
                fill="#00ced1"
                name="1 month"
                stackId="logRet"
              />
            </BarChart>
          </ResponsiveContainer>
        </ChartWatermarkContainer>
      )}
    </Box>
  );
};
