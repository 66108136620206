import {
  alpha,
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  useTheme,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import {
  ComboStrikesChart,
  ExpandableContentWrapper,
  ExpConcentrationChart,
  ExpirationConcentrationTable,
  GammaLevels,
  GammaVannaModelChart,
  OIVolumeChart,
  StrikeConcentrationTable,
  Tabs,
  TiltChart,
  ZeroDTEChart,
} from '../../components';
import {
  Candle,
  GammaVannaModel,
  IndexSymbol,
  IndexSymbols,
  IndicesContentType,
  ProductType,
  QuadrantId,
  QuadrantTab,
  QuadrantTabCategory,
  Quadrant as QuadrantType,
} from '../../types';
import {
  currentToastState,
  expConcentrationTableUnfoldState,
  histChartUnfoldState,
  indicesActiveQuadrantTabsState,
  indicesEditModalOpenState,
  indicesQuadrantsState,
  isMobileState,
  personalViewActiveState,
  searchHandlerState,
  searchSuggestionsData,
  selectedIndexState,
  selectedTabGroupState,
  strikeTableUnfoldState,
} from '../../states';
import useSetSym from '../../hooks/hiro/useSetSym';
import { TabContext, TabPanel } from '@mui/lab';
import Quadrant from './Quadrant/Quadrant';
import QuadrantEditorModal from './Quadrant/Editor/QuadrantEditorModal';
import TabButton from '../../components/core/TabButton';
import { HistoricalChart } from '../../components/indices/charts/HistoricalChart';
import { MaxRealVolChart } from '../../components/indices/charts/MaxRealVolChart';
import { OptionsRiskReversalChart } from '../../components/indices/charts/OptionsRiskReversalChart';
import { RealVolHistogramChart } from '../../components/indices/charts/RealVolHistogramChart';
import { VolatilityChart } from '../../components/indices/charts/VolatilityChart';
import {
  INDICES_NAMES,
  DEFAULT_CONTENTS_MAP,
  IndicesContentGroup,
  CONTENT_TYPE_LABEL_MAP,
} from '../../config';
import useUserDetails from '../../hooks/user/useUserDetails';
import {
  DEFAULT_INDICES_QUADRANT_MAP,
  getDefaultSymOptions,
  isContentSuppressed,
} from '../../util/indices';
import useRealizedVol from 'hooks/indices/useRealizedVol';

export const Indices = () => {
  const setToast = useSetRecoilState(currentToastState);
  // 4 quadrants, use map keys 1-4 to reference them
  const quadrants = useRecoilValue(indicesQuadrantsState);
  const [draftQuadrants, setDraftQuadrants] =
    useState<Map<QuadrantId, QuadrantType>>(quadrants);
  const [activeQuadrantTabs, setActiveQuadrantTabs] = useRecoilState(
    indicesActiveQuadrantTabsState,
  );

  const [isEditModalOpen, setIsEditModalOpen] = useRecoilState(
    indicesEditModalOpenState,
  );
  const { getDailyCandles } = useRealizedVol();

  const [dailyCandlesData, setDailyCandlesData] = useState<Candle[]>([]);
  const [dailyCandlesDataLoading, setDailyCandlesDataLoading] =
    useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useRecoilState(selectedIndexState);
  const [isPersonalViewActive, setIsPersonalViewActive] = useRecoilState(
    personalViewActiveState,
  );
  const [selectedTabGroup, setSelectedTabGroup] = useRecoilState(
    selectedTabGroupState,
  );
  const [isHistChartUnfolded, setHistChartUnfolded] =
    useRecoilState(histChartUnfoldState);

  const [isExpConcentrationTableUnfolded, setIsExpConcentrationTableUnfolded] =
    useRecoilState(expConcentrationTableUnfoldState);

  const [isStrikeTableUnfolded, setIsStrikeTableUnfolded] = useRecoilState(
    strikeTableUnfoldState,
  );

  const isMobile = useRecoilValue(isMobileState);
  const [symChangeLoadingMap, setSymChangeLoadingMap] = useState<
    Map<string, boolean>
  >(new Map<string, boolean>());

  const theme = useTheme();
  const setSuggestionsData = useSetRecoilState(searchSuggestionsData);
  const setSuggestionsHandler = useSetRecoilState(searchHandlerState);
  const { getSym, setSym, getSymRaw } = useSetSym();
  const { saveSgSettings } = useUserDetails();

  const generateChartData = async (sym: string) => {
    setDailyCandlesDataLoading(true);
    const dailyCloses = await getDailyCandles(sym);
    const data = dailyCloses[sym]?.values;
    if (data) {
      setDailyCandlesData(data);
    }
    setDailyCandlesDataLoading(false);
  };

  useEffect(() => {
    // search suggestions data click handler
    const handler = (value: string) => {
      const sym = value.toUpperCase();
      setSelectedIndex(
        IndexSymbols.has(sym as IndexSymbol)
          ? (sym as IndexSymbol)
          : IndexSymbol.SPX,
      );
    };

    setSuggestionsHandler(() => handler);
  }, [setSuggestionsHandler, setSelectedIndex]);

  useEffect(() => {
    // search suggestions data
    setSuggestionsData(INDICES_NAMES);
  }, [setSuggestionsData]);

  useEffect(() => {
    if (isPersonalViewActive) {
      setSym(null, ProductType.INDICES);
    } else {
      const sym = getSym(ProductType.INDICES);
      const selectedSym =
        sym in IndexSymbol
          ? IndexSymbol[sym as keyof typeof IndexSymbol]
          : IndexSymbol.SPX;
      setSelectedIndex(selectedSym);
      generateChartData(selectedSym);
      setSym(selectedSym, ProductType.INDICES);
    }
  }, []);

  useEffect(() => {
    if (activeQuadrantTabs.size === 0) {
      setActiveQuadrantTabs(
        new Map(
          Array.from(quadrants, ([quadrantId, { tabs }]) => [
            quadrantId,
            tabs[0]?.id,
          ]),
        ),
      );
    }
  }, [activeQuadrantTabs, quadrants]);

  const containerHeight = isMobile ? '90vh' : 'calc(50vh - 37px)';
  const defaultChartStyles = {
    padding: '12px',
    borderRadius: '8px',
    border: `1px solid ${alpha(theme.palette.primary.main, 0.35)}`,
  };

  const defaultContentContainerStyles = {
    backgroundColor: theme.palette.background.default,
    padding: '12px',
    borderRadius: '8px',
  };

  const tabOptions = [...DEFAULT_CONTENTS_MAP.keys()].reduce(
    (map, item) => map.set(item, item),
    new Map<string, string>(),
  );

  const handleChangeTab = (
    _event: React.SyntheticEvent,
    newValue: IndicesContentGroup,
  ) => {
    setSelectedTabGroup(newValue);
  };

  const ALL_INDICES_QUADRANT_TABS: QuadrantTab[] = [
    ...[...DEFAULT_INDICES_QUADRANT_MAP.values()].flatMap(
      (q: QuadrantType) => q.tabs,
    ),
  ];

  const allAvailableQuadrantTabs: QuadrantTab[] = [
    ...ALL_INDICES_QUADRANT_TABS,
    {
      id: IndicesContentType.HISTORICAL_CHART,
      contentId: IndicesContentType.HISTORICAL_CHART,
      label: CONTENT_TYPE_LABEL_MAP.get(IndicesContentType.HISTORICAL_CHART)!,
      category: QuadrantTabCategory.CHART,
      sym: IndexSymbol.SPX,
      symOptions: getDefaultSymOptions(IndicesContentType.HISTORICAL_CHART),
    },
    {
      id: IndicesContentType.EXP_CONCENTRATION_TABLE,
      contentId: IndicesContentType.EXP_CONCENTRATION_TABLE,
      label: CONTENT_TYPE_LABEL_MAP.get(
        IndicesContentType.EXP_CONCENTRATION_TABLE,
      )!,
      category: QuadrantTabCategory.TABLE,
      sym: IndexSymbol.SPX,
      symOptions: getDefaultSymOptions(
        IndicesContentType.EXP_CONCENTRATION_TABLE,
      ),
    },
    {
      id: IndicesContentType.STRIKE_CONCENTRATION_TABLE,
      contentId: IndicesContentType.STRIKE_CONCENTRATION_TABLE,
      label: CONTENT_TYPE_LABEL_MAP.get(
        IndicesContentType.STRIKE_CONCENTRATION_TABLE,
      )!,
      category: QuadrantTabCategory.TABLE,
      sym: IndexSymbol.SPX,
      symOptions: getDefaultSymOptions(
        IndicesContentType.STRIKE_CONCENTRATION_TABLE,
      ),
    },
  ];

  const handleChartSymChange = useCallback(
    async (newSym: string, tabId: string) => {
      setSymChangeLoadingMap((prev) => {
        const newLoadingState = new Map(prev);
        newLoadingState.set(tabId, true);
        return newLoadingState;
      });

      const updatedQuadrantsMap = Array.from(quadrants.entries()).map(
        ([quadrantId, quadrant]): [QuadrantId, QuadrantType] => {
          const updatedTabs = quadrant.tabs.map((tab) =>
            tab.id === tabId ? { ...tab, sym: newSym } : tab,
          );

          return [quadrantId, { ...quadrant, tabs: updatedTabs }];
        },
      );
      const resp = await saveSgSettings(
        {
          indicesQuadrants: Object.fromEntries(
            new Map(updatedQuadrantsMap),
          ) as {
            [key in QuadrantId]: QuadrantType;
          },
        },
        true,
      );

      if (resp?.error != null) {
        setToast({
          message:
            'Something went wrong while changing the symbol. Try again or contact us if the issue persists.',
          type: 'error',
          duration: 10_000,
        });
      } else {
        setDraftQuadrants(new Map(updatedQuadrantsMap));
      }

      setSymChangeLoadingMap((prev) => {
        const newLoadingState = new Map(prev);
        newLoadingState.set(tabId, false);
        return newLoadingState;
      });
    },
    [quadrants, setSymChangeLoadingMap, setDraftQuadrants],
  );

  const contentForTypeMap = new Map<string, JSX.Element>([
    [
      IndicesContentType.GAMMA_MODEL, // gamma model
      <ExpandableContentWrapper type={IndicesContentType.GAMMA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.GAMMA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.DELTA_MODEL, // delta model
      <ExpandableContentWrapper type={IndicesContentType.DELTA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.DELTA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VANNA_MODEL, // vanna model
      <ExpandableContentWrapper type={IndicesContentType.VANNA_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.VANNA}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.GAMMA_LEVELS, // gamma levels
      <ExpandableContentWrapper type={IndicesContentType.GAMMA_LEVELS}>
        <GammaLevels
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.COMBO_STRIKES, // combo strikes
      <ExpandableContentWrapper type={IndicesContentType.COMBO_STRIKES}>
        <ComboStrikesChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.TILT, // gamma tilt
      <ExpandableContentWrapper type={IndicesContentType.TILT}>
        <TiltChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.EXP_CONCENTRATION, // Expiration Concentration
      <ExpandableContentWrapper type={IndicesContentType.EXP_CONCENTRATION}>
        <ExpConcentrationChart
          isIndices
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.ZERO_DTE, // 0DTE Volume/Open Interest
      <ExpandableContentWrapper type={IndicesContentType.ZERO_DTE}>
        <ZeroDTEChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VOLFORECAST_MODEL, // SIV Index
      <ExpandableContentWrapper type={IndicesContentType.VOLFORECAST_MODEL}>
        <GammaVannaModelChart
          model={GammaVannaModel.VOLFORECAST}
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.OPTIONS_RISK_REVERSAL_CHART, // Risk Reversal
      <ExpandableContentWrapper
        type={IndicesContentType.OPTIONS_RISK_REVERSAL_CHART}
      >
        <OptionsRiskReversalChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.MAX_REAL_VOL, // price vs volatility
      <ExpandableContentWrapper type={IndicesContentType.MAX_REAL_VOL}>
        <MaxRealVolChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
          data={dailyCandlesData}
          isLoading={dailyCandlesDataLoading}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.VOLATILITY, // price vs volatility
      <ExpandableContentWrapper type={IndicesContentType.VOLATILITY}>
        <VolatilityChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
          data={dailyCandlesData}
          isLoading={dailyCandlesDataLoading}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.HIST_RETURNS, // returns histogram
      <ExpandableContentWrapper type={IndicesContentType.HIST_RETURNS}>
        <RealVolHistogramChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
          data={dailyCandlesData}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.OI_VOLUME, // OI & Volume
      <ExpandableContentWrapper type={IndicesContentType.OI_VOLUME}>
        <OIVolumeChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.HISTORICAL_CHART, // gamma model
      <ExpandableContentWrapper type={IndicesContentType.HISTORICAL_CHART}>
        <HistoricalChart
          chartStyleOverrides={defaultChartStyles}
          containerStyleOverrides={defaultContentContainerStyles}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.EXP_CONCENTRATION_TABLE, // gamma model
      <ExpandableContentWrapper
        type={IndicesContentType.EXP_CONCENTRATION_TABLE}
      >
        <ExpirationConcentrationTable
          containerStyleOverrides={{
            ...defaultContentContainerStyles,
          }}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
    [
      IndicesContentType.STRIKE_CONCENTRATION_TABLE, // gamma model
      <ExpandableContentWrapper
        type={IndicesContentType.STRIKE_CONCENTRATION_TABLE}
      >
        <StrikeConcentrationTable
          containerStyleOverrides={{
            ...defaultContentContainerStyles,
          }}
          selectedSym={selectedIndex}
        />
      </ExpandableContentWrapper>,
    ],
  ]);

  const changeQuadrantActiveTab = (quadrantId: QuadrantId, newTabId: string) =>
    setActiveQuadrantTabs((oldActiveQuadrants) =>
      new Map(oldActiveQuadrants).set(quadrantId, newTabId),
    );

  return (
    <Stack
      sx={{
        width: '100%',
        paddingBottom: '70px',
      }}
      gap={isMobile ? '36px' : '48px'}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent={{
            xs: 'normal',
            sm: 'center',
          }}
          gap={isMobile ? '12px' : '36px'}
          sx={{ overflowX: 'auto' }}
        >
          <Stack direction="row" gap={isMobile ? 1 : 2}>
            {INDICES_NAMES.map(({ symbol }) => (
              <TabButton
                key={symbol}
                label={symbol}
                onClick={() => {
                  setSelectedIndex(symbol as IndexSymbol);
                  setSym(symbol as IndexSymbol, ProductType.INDICES);
                  setIsPersonalViewActive(false);
                  generateChartData(symbol);
                }}
                isActive={symbol === getSymRaw()}
              />
            ))}
          </Stack>
          <TabButton
            label="Personal View"
            onClick={() => {
              setIsPersonalViewActive(true);
              setSym(null, ProductType.INDICES);
            }}
            isActive={isPersonalViewActive}
            onEdit={() => setIsEditModalOpen(true)}
          />
        </Stack>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingX: isMobile ? '4px' : '16px',
            paddingTop: '8px',
            paddingBottom: '16px',
            borderRadius: '8px',
          }}
        >
          {isPersonalViewActive ? (
            <Grid container spacing="16px">
              {[...quadrants.entries()].map(([idx, quadrant]) => (
                <Grid key={idx} item xs={12} lg={6}>
                  <Quadrant
                    tabs={quadrant.tabs}
                    activeTabId={activeQuadrantTabs.get(idx)}
                    onSymChange={handleChartSymChange}
                    symChangeLoadingMap={symChangeLoadingMap}
                    setActiveTabId={(id: string) =>
                      changeQuadrantActiveTab(idx, id)
                    }
                    setEditorOpen={setIsEditModalOpen}
                    sx={{
                      height: containerHeight,
                      width: '100%',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <TabContext value={selectedTabGroup as string}>
              <Tabs
                options={tabOptions}
                onChange={handleChangeTab}
                defaultScrollable={true}
                fontSize={isMobile ? '12px' : '16px'}
                isFullWidth
                selected={selectedTabGroup}
              />

              {[...DEFAULT_CONTENTS_MAP.entries()].map(
                ([group, contentTypes]: [
                  IndicesContentGroup,
                  IndicesContentType[],
                ]) => (
                  <TabPanel key={group} value={group}>
                    <Grid container spacing={6}>
                      {contentTypes
                        .filter(
                          (cType: IndicesContentType) =>
                            !isContentSuppressed(
                              cType,
                              selectedIndex as string,
                            ),
                        )
                        .map(
                          (
                            cType: IndicesContentType,
                            index: number,
                            array: IndicesContentType[],
                          ) => (
                            <Grid
                              key={cType}
                              item
                              xs={12}
                              md={
                                // This is to make the 3 "model" charts span in one row, otherwise default to 2 charts per row unless odd count of items
                                group === IndicesContentGroup.GREEKS &&
                                (cType === IndicesContentType.GAMMA_MODEL ||
                                  cType === IndicesContentType.DELTA_MODEL ||
                                  cType === IndicesContentType.VANNA_MODEL)
                                  ? 4
                                  : index === array.length - 1 &&
                                    array.length % 2 !== 0 &&
                                    group !== IndicesContentGroup.GREEKS
                                  ? 12
                                  : 6
                              }
                            >
                              <Box
                                sx={{
                                  height: containerHeight,
                                  padding: 1,
                                }}
                              >
                                {contentForTypeMap.get(cType)}
                              </Box>
                            </Grid>
                          ),
                        )}
                    </Grid>
                  </TabPanel>
                ),
              )}
            </TabContext>
          )}
        </Paper>
      </Stack>

      <QuadrantEditorModal
        title="Customize Indices"
        settingsSaveKey="indicesQuadrants"
        quadrants={quadrants}
        draftQuadrants={draftQuadrants}
        setDraftQuadrants={setDraftQuadrants}
        setActiveQuadrantTabs={setActiveQuadrantTabs}
        isOpen={isEditModalOpen}
        setIsOpen={setIsEditModalOpen}
        allAvailableQuadrantTabs={allAvailableQuadrantTabs}
        defaultQuadrantsMap={DEFAULT_INDICES_QUADRANT_MAP}
        availableContentCategories={[
          QuadrantTabCategory.CHART,
          QuadrantTabCategory.TABLE,
        ]}
      />

      {!isPersonalViewActive && (
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            paddingX: isMobile ? '4px' : '16px',
            paddingY: '16px',
            borderRadius: '8px',
          }}
        >
          <Box>
            <ExpandableContentWrapper
              type={IndicesContentType.HISTORICAL_CHART}
              styleOverrides={{
                height: isHistChartUnfolded ? containerHeight : 'inherit',
                width: '100%',
              }}
            >
              <HistoricalChart
                chartStyleOverrides={defaultChartStyles}
                selectedSym={selectedIndex}
                containerStyleOverrides={defaultContentContainerStyles}
                setIsUnfolded={setHistChartUnfolded}
                isUnfolded={isHistChartUnfolded}
              />
            </ExpandableContentWrapper>
          </Box>

          <Divider
            sx={{
              borderWidth: '1px',
              borderColor: alpha(theme.palette.primary.main, 0.2),
            }}
          />

          <Box>
            <ExpandableContentWrapper
              type={IndicesContentType.EXP_CONCENTRATION_TABLE}
            >
              <ExpirationConcentrationTable
                containerStyleOverrides={{
                  ...defaultContentContainerStyles,
                }}
                selectedSym={selectedIndex}
                setIsUnfolded={setIsExpConcentrationTableUnfolded}
                isUnfolded={isExpConcentrationTableUnfolded}
              />
            </ExpandableContentWrapper>
          </Box>

          <Divider
            sx={{
              borderWidth: '1px',
              borderColor: alpha(theme.palette.primary.main, 0.2),
            }}
          />

          <Box>
            <ExpandableContentWrapper
              type={IndicesContentType.STRIKE_CONCENTRATION_TABLE}
            >
              <StrikeConcentrationTable
                containerStyleOverrides={{
                  ...defaultContentContainerStyles,
                }}
                selectedSym={selectedIndex}
                setIsUnfolded={setIsStrikeTableUnfolded}
                isUnfolded={isStrikeTableUnfolded}
              />
            </ExpandableContentWrapper>
          </Box>
        </Paper>
      )}
    </Stack>
  );
};
